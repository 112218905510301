<template>
  <div style="padding: 0px 15px">
    <div class="result-success">
      <div class="result-success-icon">
        <van-image
          :src="require('@/assets/icons/icon_success.png')"
          width="85"
          height="85"
        />
      </div>
      <div class="result-success-title">{{ title }}</div>
      <div class="result-success-subtitle">{{ subtitle }}</div>
    </div>

    <div class="customer-service">
      {{ $t("我们将尽快为您配送上门，感谢您的支持。") }}
    </div>
    <div class="customer-service" @click="copyWechatId">
      <p style="color: #969696">
        {{ $t("如有问题请联系客服微信") }} {{ wechatId }}
        <span style="color: #569cd6">{{ $t("[复制]") }}</span>
      </p>
    </div>
    <div class="action-bar">
      <van-button type="default" @click="gotoCargoList">{{
        $t("前往查件")
      }}</van-button>
    </div>

    <van-popup
      v-model="showPacketDialog"
      :close-on-click-overlay="false"
      :overlay-style="{
        'background-color': 'rgba(0, 0, 0, 0.2)',
      }"
    >
      <div style="width: 300px">
        <div style="text-align: center; margin-top: 10px">
          <van-image
            :src="require('@/assets/icons/redpacket.jpg')"
            class="redpacket"
          />
        </div>
        <div style="margin-bottom: 15px; margin-top: 5px">
          <div style="font-size: 18px; color: #ee0a24; text-align: center">
            {{ $t("恭喜获得amount泰铢红包", { amount: amount }) }}
          </div>
          <div style="font-size: 16px; text-align: center; margin-top: 10px">
            {{ $t("分享给小伙伴，大家一起抢") }}
          </div>
        </div>
        <div class="redpacket-btns">
          <div class="redpacket-btn" style="border-right: 1px solid #ebedf0">
            <van-button
              style="color: #ee0a24; font-size: 16px; border: none"
              @click="handlePacketDialogConfirm()"
              plain
              block
              open-type="share"
            >
              {{ $t("分享到Line领取") }}
            </van-button>
          </div>
          <div class="redpacket-btn">
            <van-button
              style="font-size: 16px; border: none"
              @click="handlePacketDialogClose"
              block
            >
              {{ $t("下次再说") }}
            </van-button>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Button, Toast, Dialog, Image as VanImage, Popup } from "vant";
import { redPacketSend } from "@/service/api";
import { base10To26 } from "@/utils/utils";

export default {
  components: {
    [Button.name]: Button,
    [Toast.name]: Toast,
    [VanImage.name]: VanImage,
    [Popup.name]: Popup,
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      title: this.$t("下单成功"),
      subtitle: "",
      wechatId: "@549tcrhe",
      orderNo: this.$route.query.orderNo,
      showPacketDialog: false,
      amount: 0,
      redpacket: null,
    };
  },
  mounted() {
    // this.sendRedPacket();
  },
  methods: {
    goback() {
      this.$router.go(-1);
    },
    gotoCargoList() {
      this.$router.push({ name: "Home" });
    },
    async sendRedPacket() {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: this.$t("..."),
      });

      const res = await redPacketSend(this.orderNo);
      if (res.success) {
        Toast.clear();
        const { data } = res;
        this.amount = data.totalAmount;
        this.redpacket = data;
        this.showPacketDialog = true;
      } else Toast.fail(res.message);
    },
    handlePacketDialogConfirm() {
      this.showPacketDialog = false;

      const redPacketId = base10To26(this.redpacket.id);

      const liffId =
        process.env.NODE_ENV === "production"
          ? "1656085016-mDXQxeGq"
          : "1656085016-0DPWQKwE";

      const url =
        "https://liff.line.me/" +
        liffId +
        "?redirect=redpacket&rp=" +
        redPacketId;

      const shareUrl =
        "https://social-plugins.line.me/lineit/share?url=" +
        encodeURIComponent(url);
      window.open(shareUrl);
    },
    handlePacketDialogClose() {
      this.showPacketDialog = false;
    },
    copyWechatId() {
      let oInput = document.createElement("input");
      oInput.value = this.wechatId;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象;
      document.execCommand("Copy"); // 执行浏览器复制命令
      Toast.success(this.$t("复制成功"));
      oInput.remove();
    },
  },
};
</script>
<style lang="less" scoped>
/* pages/success/index.wxss */
page {
  background-color: #fff;
}

.result-success {
  padding: 15px 0;
}

.result-success-icon {
  margin-bottom: 18px;
  text-align: center;
}

.result-success-title {
  color: rgba(0, 0, 0, 0.85);
  font-size: 24px;
  line-height: 1.8;
  text-align: center;
}

.result-success-subtitle {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.6;
  text-align: center;
}

.customer-service {
  color: #969696;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.action-bar {
  margin-top: 20px;
  text-align: center;
}

.redpacket {
  width: 90px;
  height: 90px;
}

.redpacket-btns {
  margin-top: 5px;
  display: flex;
  border-top: 1px solid #ebedf0;
}

.redpacket-btn {
  flex: 1;
  padding: 5px 0;
}

.van-popup {
  border-radius: 10px;
}
</style>